import { faClockRotateLeft, faSearch } from "@fortawesome/pro-regular-svg-icons"
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isPractitioner, MedicationKnowledge } from "fhir"
import { Button } from "primereact/button"
import { Calendar } from "primereact/calendar"
import { Dialog } from "primereact/dialog"
import { classNames } from "primereact/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom"

import { SimpleBreadCrumb, SkeletonLoader, useChargeItemDefinitions, useSidebarContext } from "commons"
import { MedicationKnowledgeDetails, useMedicationRequestDataBind, useMrOrderDetails } from "commons/meds"
import { BillingTypeCodes, systems } from "data"
import { InvoiceListItem } from "invoices"
import { useLoginContext } from "security"
import { getServiceRequestBillingType, getSubviewPath } from "utils"

import { getOrderDate } from "../../data"
import { useCancelMrOrder, useRescheduleMrOrder } from "../../hooks"
import { TrackingData } from "../../types"
import { MedicationDataItem } from "../common"
import { CancelOrderConfirmation } from "./CancelOrderConfirmation"
import { TaskListItem } from "./TaskListItem"
import { TrackingCodeItem } from "./TrackingCodeItem"

const MedicationOrderDetails = () => {
  const navigate = useNavigate()
  const { loggedInPatientId } = useLoginContext()
  const { organizationId } = useSidebarContext()
  const [params, setParams] = useSearchParams()
  const [selectedMK, setSelectedMK] = useState<MedicationKnowledge>()
  const [cancelOrderId, setCancelOrderId] = useState<string>()
  const [rescheduleOrderId, setRescheduleOrderId] = useState<string>()
  const [rescheduleDate, setRescheduleDate] = useState<Date | undefined>()

  const isReschedule = useMemo(() => !!rescheduleDate, [rescheduleDate])

  const orderId = params.get("order")
  const goToOrders = () => {
    params.delete("order")
    setParams(params)
  }
  useEffect(() => {
    if (!orderId) goToOrders()
  })

  const {
    serviceRequest,
    medicationKnowledges,
    medicationRequests,
    medicationDispenses,
    tasks,
    invoice,
    isLoading,
    medCodes,
    hasAutoship,
  } = useMrOrderDetails(loggedInPatientId, orderId as string)
  const { chargeItemDefinitions } = useChargeItemDefinitions(organizationId, {
    billToPracticeOrInsuranceCIDs: medCodes,
  })

  const billingType = getServiceRequestBillingType(serviceRequest)

  const { medicationRequestData } = useMedicationRequestDataBind(
    medicationRequests,
    medicationKnowledges,
    chargeItemDefinitions.billToPracticeOrInsuranceCIDs,
  )

  const { cancelMrOrder, isCancelling } = useCancelMrOrder(() => setCancelOrderId(undefined))

  const hideRescheduleDialog = () => {
    setRescheduleOrderId(undefined)
  }
  const { rescheduleMrOrder, isRescheduling } = useRescheduleMrOrder(hideRescheduleDialog)

  const cancelOrder = (orderId: string, reason: string, stopOrder: boolean) =>
    cancelMrOrder({
      patientId: loggedInPatientId,
      serviceRequestId: orderId,
      cancelReason: reason,
      stopSequence: stopOrder,
    })

  const showInvoice = useCallback(() => {
    navigate({
      pathname: getSubviewPath(loggedInPatientId, "invoices"),
      search: `?${createSearchParams({ invoiceId: invoice?.id as string })}`,
    })
  }, [invoice, loggedInPatientId, navigate])

  const dispenseTrackcodes = medicationDispenses?.reduce((acc, md) => {
    if (md.identifier && md.identifier[0].value) {
      const trackinCode = md.identifier[0].value
      const medication = md.medication?.CodeableConcept?.text as string

      const item = acc.get(trackinCode)
      if (item) {
        if (!item.medications.includes(medication)) item.medications.push(medication)
      } else {
        acc.set(trackinCode, {
          identifier: trackinCode,
          date: md.whenPrepared as string,
          medications: [medication],
          status: md.status,
        })
      }
    }
    return acc
  }, new Map<string, TrackingData>())

  const shippingMethod = serviceRequest?.orderDetail?.find(
    ({ coding }) => coding?.[0]?.system === systems.shippingMethod,
  )

  const handleRescheduleOrder = (id: string, date?: Date) => {
    setRescheduleOrderId(id)

    setRescheduleDate(date)
  }

  return (
    <div className="flex flex-col h-full overflow-auto p-3 lg:px-5 pb-0">
      <SimpleBreadCrumb goHome={goToOrders} title="Order Details" />
      <div className="grid grid-cols-1 overflow-auto content-start">
        {isLoading ? (
          <>
            <SkeletonLoader loaderType="two-lines" extraLine repeats={1} />
            <SkeletonLoader loaderType="list" repeats={1} extraLine skeletonShape="rectangle" skeletonSize="6rem" />
          </>
        ) : (
          <div className="flex flex-col gap-4 my-3 overflow-auto">
            <div className="flex justify-between p-1 flex-col-reverse md:flex-row">
              <div className="grid items-start lg:inline-flex gap-4">
                <span>
                  <label className="font-semibold">Order:</label>{" "}
                  {serviceRequest?.identifier?.[0]?.value ?? "Unspecified"}
                </span>
                <span>
                  <label className="font-semibold">Date:</label> {getOrderDate(serviceRequest)}
                </span>
                <span>
                  <label className="font-semibold">Status:</label>{" "}
                  <span
                    className={`capitalize font-semibold text-${classNames({
                      "is-danger": serviceRequest?.status === "revoked",
                      "is-success": serviceRequest?.status === "completed",
                      "is-info": serviceRequest?.status === "active",
                    })}`}
                  >
                    {serviceRequest?.status === "revoked" ? "Cancelled" : serviceRequest?.status ?? "Unspecified"}
                  </span>
                </span>
                {!isPractitioner(serviceRequest?.requester) && (
                  <span title="Self prescription" className="font-semibold text-slate-500">
                    Self prescription
                  </span>
                )}
              </div>
              <div className="flex flex-row justify-center md:items-end gap-1 mb-5 md:mb-0">
                {serviceRequest?.status === "active" && (
                  <>
                    {invoice?.status !== "balanced" && (
                      <Button
                        className="p-button-sm p-button-text"
                        label="Cancel"
                        icon={<FontAwesomeIcon icon={faTrashCan} size="sm" className="mr-1" />}
                        onClick={() => setCancelOrderId(orderId as string)}
                        disabled={isCancelling}
                      />
                    )}
                    <Button
                      className="p-button-sm p-button-text"
                      label="Reschedule"
                      icon={<FontAwesomeIcon icon={faClockRotateLeft} size="sm" className="mr-1" />}
                      onClick={() => handleRescheduleOrder(orderId as string, new Date())}
                      disabled={isRescheduling || invoice?.status === "balanced"}
                    />
                    <Button
                      className="p-button-sm p-button-text"
                      label="Hold"
                      icon={<FontAwesomeIcon icon={faClockRotateLeft} size="sm" className="mr-1" />}
                      onClick={() => handleRescheduleOrder(orderId as string)}
                      disabled={isRescheduling || invoice?.status === "balanced"}
                    />
                  </>
                )}
              </div>
            </div>

            {!!tasks?.length && (
              <span className="flex flex-col">
                <label className="font-semibold">Tasks:</label>
                {tasks?.map((task) => <TaskListItem key={task.id} task={task} billingType={billingType} />)}
              </span>
            )}
            <div className="flex flex-col pr-1">
              <label className="font-semibold">Shipping method:</label>
              <span className="p-4">{shippingMethod ? shippingMethod.text : "No shipping method"}</span>
            </div>
            {dispenseTrackcodes && dispenseTrackcodes.size > 0 && (
              <div className="flex flex-col pr-1">
                <div className="font-semibold mb-2">Tracking code: </div>
                {Array.from(dispenseTrackcodes.values()).map((code, index) => (
                  <TrackingCodeItem key={index} trackinCode={code} className="last:mb-0" />
                ))}
              </div>
            )}
            {invoice && ![BillingTypeCodes.BILL_PRACTICE, BillingTypeCodes.INSURANCE].includes(billingType) && (
              <div className="flex flex-col pr-1">
                <div className="font-semibold mb-2">Invoice: </div>
                <InvoiceListItem invoice={invoice} onClick={showInvoice} className="last:mb-0" />
              </div>
            )}
            <label className="font-semibold mb-2">Medications:</label>
            {!medicationRequestData?.length ? (
              <div className="flex flex-col items-center justify-center w-full h-full">
                <FontAwesomeIcon icon={faSearch} size="3x" className="text-slate-400" />
                <p className="text-md text-slate-400 pt-4 pb-2 place-self-center">No medications requested</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 2xl:gap-3 content-start">
                {medicationRequestData?.map((medicationData, index) => (
                  <MedicationDataItem
                    key={index}
                    medicationKnowledge={medicationData.medicationKnowledge}
                    medicationRequest={medicationData.medicationRequestInfo}
                    showInstructions
                    showPackagingType
                    showStatus
                    cardStyle
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <MedicationKnowledgeDetails selectedMK={selectedMK} onHide={() => setSelectedMK(undefined)} />
      <CancelOrderConfirmation
        orderId={cancelOrderId}
        hasAutoship={hasAutoship}
        invoiceNumber={invoice?.status === "balanced" ? invoice?.identifier?.[0]?.value : undefined}
        onHide={() => setCancelOrderId(undefined)}
        onSubmit={({ orderId, reason, stopOrder }) => cancelOrder(orderId, reason, stopOrder)}
      />
      <Dialog
        closable={true}
        header={isReschedule ? "Reschedule order" : "Hold order"}
        visible={!!rescheduleOrderId}
        draggable={false}
        dismissableMask={true}
        className={classNames(
          "w-full",
          { "md:w-1/3": isReschedule },
          { "md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5": !isReschedule },
        )}
        onHide={hideRescheduleDialog}
        footer={
          <div className="mt-2">
            <Button
              label="Cancel"
              disabled={isRescheduling}
              className="button-default"
              onClick={hideRescheduleDialog}
            />
            <Button
              label={isReschedule ? "Reschedule" : "Hold"}
              className="button-primary m-0"
              loading={isRescheduling}
              onClick={() => {
                rescheduleMrOrder({ id: orderId as string, newDate: rescheduleDate })
              }}
            />
          </div>
        }
      >
        {isReschedule ? (
          <>
            <label>New date</label>
            <Calendar
              className="w-full mt-2"
              showIcon
              value={rescheduleDate}
              minDate={new Date()}
              dateFormat={"M d, yy"}
              readOnlyInput
              onChange={(e) => setRescheduleDate(e.target.value as Date)}
            />
          </>
        ) : (
          <p>Are you sure you want to hold-on this order?</p>
        )}
      </Dialog>
    </div>
  )
}

export { MedicationOrderDetails }
