import {
  faBuilding,
  faEnvelope,
  faLocationDot,
  faPhone,
  faSquareArrowDown,
  faUserDoctor,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getFirstEmail, getFirstEmailNoFallback, getFirstPhone, getFirstPhoneNoFallback, humanNameAsString } from "fhir"
import { Tooltip } from "primereact/tooltip"
import { classNames } from "primereact/utils"
import { CSSProperties, FC } from "react"

import { Badge, BadgeProps, Button, useShowSignedUrlDocument } from "commons"
import { getStringAddress } from "utils"

import { LabPanelResult } from "../types"

const ResultDetails: FC<IProps> = ({ labPanelResult }) => {
  const { showDocument, loading } = useShowSignedUrlDocument()

  let value: number = NaN
  try {
    value = labPanelResult?.value ? parseFloat(labPanelResult.value.toString().match(/(\d+(\.\d*)?)/)?.[0] ?? "") : NaN
  } catch (error) {
    //not a number
    value = NaN
  }

  const low = labPanelResult?.referenceRange?.low?.value ?? 0
  const high = labPanelResult?.referenceRange?.high?.value ?? low * 2.5
  const hasRange = low > 0 || high > 0
  const minAvg = Math.min(low, (high - low) / 3)
  const max = (high === 0 ? 1 : high) - low + (minAvg > 0 ? minAvg * 2 : (high - low) / 3)
  const lowPercent = (minAvg / max) * 100
  const middlePercent = ((high - low) / max) * 100
  const highPercent = 100 - middlePercent - lowPercent
  const valuePercent = hasRange && !isNaN(value) ? ((value - low + minAvg) / max) * 100 : 50
  const badge: BadgeProps | undefined = !isNaN(value)
    ? value < low
      ? { text: "Below low normal", colorStyle: "yellow" }
      : value > high
        ? { text: "Above high normal", colorStyle: "red" }
        : { text: "Normal", colorStyle: "green" }
    : undefined
  const borderColor = !isNaN(value)
    ? value < low
      ? "border-yellow-500"
      : value > high && high > 0
        ? "border-red-500"
        : "border-green-500"
    : ""
  // Handle negative percentaje when too low as 0
  const valueStyles: CSSProperties = hasRange ? { left: `${Math.min(100, Math.max(valuePercent, 0))}%` } : { right: 0 }

  return (
    <div className="flex flex-col pt-5 first:pt-0">
      <div className="grid grid-cols-3 text-sm text-gray-500 items-center">
        <div className="flex space-x-6 items-baseline">
          <div className="flex flex-col">
            <span title="Code" className="font-medium">
              {labPanelResult.code} {!labPanelResult?.value && labPanelResult?.unit && `(${labPanelResult.unit})`}
            </span>
            {labPanelResult?.placeOfService && (
              <>
                <span id={`POS_O_${labPanelResult.key}`} className="text-xs font-semibold truncate cursor-default">
                  <FontAwesomeIcon icon={faBuilding} className="mr-1" />
                  {labPanelResult.placeOfService.name}
                </span>
                <Tooltip
                  target={`#POS_O_${labPanelResult.key}`}
                  position="bottom"
                  pt={{ text: { className: "bg-white border" } }}
                >
                  <div className="flex flex-col w-fit p-2 text-xs">
                    <span className="text-gray-700 font-semibold mb-1">{labPanelResult.placeOfService.name}</span>
                    {!!labPanelResult.placeOfService.address?.[0] && (
                      <span className="text-gray-500">
                        <FontAwesomeIcon icon={faLocationDot} className="fa-fw mr-1" />
                        {getStringAddress(labPanelResult.placeOfService.address[0])}
                      </span>
                    )}
                    {getFirstEmailNoFallback(labPanelResult.placeOfService.telecom) && (
                      <span className="text-gray-500">
                        <FontAwesomeIcon icon={faEnvelope} className="fa-fw mr-1" />
                        {getFirstEmail(labPanelResult.placeOfService.telecom)}
                      </span>
                    )}
                    {getFirstPhoneNoFallback(labPanelResult.placeOfService.telecom) && (
                      <span className="text-gray-500">
                        <FontAwesomeIcon icon={faPhone} className="fa-fw mr-1" />
                        {getFirstPhone(labPanelResult.placeOfService.telecom)}
                      </span>
                    )}
                    {labPanelResult.placeOfService.contact?.[0]?.name && (
                      <span className="text-gray-500">
                        <FontAwesomeIcon icon={faUserDoctor} className="fa-fw mr-1" />
                        {humanNameAsString(labPanelResult.placeOfService.contact[0].name)}
                      </span>
                    )}
                  </div>
                </Tooltip>
              </>
            )}
          </div>
          {labPanelResult?.attachment?.url && (
            <Button
              label="Download"
              buttonStyle="outlined"
              size="xs"
              icon={faSquareArrowDown}
              onClick={() => showDocument(labPanelResult?.attachment?.url)}
              loading={loading}
            />
          )}
        </div>

        {labPanelResult.value && (
          <>
            <div className="flex justify-center items-center">{hasRange && badge && <Badge {...badge} />}</div>
            <div className="flex items-center justify-end text-sm text-gray-500">
              <div className="relative w-64">
                <div className={classNames("relative w-full h-8", { "flex items-center": !hasRange })}>
                  {hasRange && (
                    <div className="flex absolute top-2 gap-1 w-full rounded-full bg-white">
                      {low > 0 && (
                        <div className="rounded-full h-2 bg-yellow-500" style={{ width: `${lowPercent}%` }} />
                      )}
                      <div className="rounded-full h-2 bg-green-500" style={{ width: `${middlePercent}%` }} />
                      <div className="rounded-full h-2 bg-red-500" style={{ width: `${highPercent}%` }} />
                    </div>
                  )}
                  <div
                    className={classNames(
                      "flex items-center justify-center min-w-8 bg-white",
                      isNaN(value) ? "h-full" : "absolute",
                      !isNaN(value) ? (valuePercent < 90 ? "-translate-x-1/2" : "-translate-x-3/4") : undefined,
                      { "px-1.5 rounded-full border-2 top-0": hasRange && !isNaN(value) },
                      { [borderColor]: hasRange },
                    )}
                    style={valueStyles}
                  >
                    {!isNaN(value) && (
                      <>{hasRange ? labPanelResult.value : `${labPanelResult.value} ${labPanelResult.unit}`}</>
                    )}
                  </div>
                </div>
                {hasRange && (
                  <div
                    className="flex justify-between items-baseline relative min-w-fit flex-1"
                    style={{ left: `${lowPercent}%`, width: `${middlePercent}%` }}
                  >
                    <span>{low}</span>
                    {!!labPanelResult.unit && <span>{` - (${labPanelResult.unit}) - `}</span>}
                    <span>{high}</span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      {!!labPanelResult.notes?.length && (
        <div className="w-full flex flex-col gap-1 mt-4 p-3 rounded-lg bg-gray-100 text-gray-500">
          <ul className="overflow-y-auto list-inside space-y-2">
            {labPanelResult.notes?.map((note, index) => (
              <li key={index} className="text-sm">
                <span className="whitespace-pre-wrap">
                  {note.text
                    ?.trim()
                    ?.replace(/\.\\n|\\n {4}/g, "\n")
                    ?.replace(/\\n| {2,}/g, " ")}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

type IProps = { labPanelResult: LabPanelResult }

export { ResultDetails }
